import { useEffect } from "react";

const SearchForm = () => {
  useEffect(() => {
    // Выполните ваш код инициализации здесь, например, добавьте скрипт в DOM
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//tourvisor.ru/module/init.js";
    document.querySelector(".tv-search-form")?.appendChild(script);

    // Очистите ресурсы при размонтировании компонента
    return () => {
      document.querySelector(".tv-search-form")?.removeChild(script);
    };
  }, []);
  return (
    <section className="searchForm">
      <div className="wrapperForm">
        <div className="tv-search-form tv-moduleid-9964053"></div>
      </div>
    </section>
  );
};

export default SearchForm;
