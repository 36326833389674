import visa from "../../../assets/img/payIcons/visa.png";
import visaSecure from "../../../assets/img/payIcons/visaSecure.png";
import idCheck from "../../../assets/img/payIcons/idCheck.png";
import mastercard from "../../../assets/img/payIcons/mastercard.png";
import mir from "../../../assets/img/payIcons/mir.png";
import belkart from "../../../assets/img/payIcons/belkart.png";
import vtb from "../../../assets/img/payIcons/vtb.png";
import alfaBank from "../../../assets/img/payIcons/alfaBank.png";

export const dataIcons = [
  <img src={visa} alt="visa icon"></img>,
  <img src={visaSecure} alt="visaSecure icon"></img>,
  <img src={idCheck} alt="idCheck icon"></img>,
  <img src={mastercard} alt="mastercard icon"></img>,
  <img src={mir} alt="mir icon"></img>,
  <img src={belkart} alt="belkart icon"></img>,
  <img src={vtb} alt="vtb icon"></img>,
  <img src={alfaBank} alt="alfaBank icon"></img>,
];
