import { dataIcons } from "./constants/dataIcons";

const PayIcons = () => {
  return (
    <section className="payIcons">
      {dataIcons.map((icon, index) => {
        return (
          <div className="payIcons__icon" key={index}>
            {icon}
          </div>
        );
      })}
    </section>
  );
};

export default PayIcons;
