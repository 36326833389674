export const Place = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_69_164)">
        <path
          d="M10 1.66666C6.77502 1.66666 4.16669 4.27499 4.16669 7.49999C4.16669 11.875 10 18.3333 10 18.3333C10 18.3333 15.8334 11.875 15.8334 7.49999C15.8334 4.27499 13.225 1.66666 10 1.66666ZM10 9.58332C8.85002 9.58332 7.91669 8.64999 7.91669 7.49999C7.91669 6.34999 8.85002 5.41666 10 5.41666C11.15 5.41666 12.0834 6.34999 12.0834 7.49999C12.0834 8.64999 11.15 9.58332 10 9.58332Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_164">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Time = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.19989 6H10.3999V10.2L13.9999 12.336L13.3999 13.32L9.19989 10.8V6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10C2 5.584 5.576 2 9.992 2C14.416 2 18 5.584 18 10C18 14.416 14.416 18 9.992 18C5.576 18 2 14.416 2 10ZM3.6 10C3.6 13.536 6.464 16.4 10 16.4C13.536 16.4 16.4 13.536 16.4 10C16.4 6.464 13.536 3.6 10 3.6C6.464 3.6 3.6 6.464 3.6 10Z"
        fill="white"
      />
    </svg>
  );
};
export const Phone = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_69_173)">
        <path
          d="M5.51667 8.99167C6.71667 11.35 8.65 13.275 11.0083 14.4833L12.8417 12.65C13.0667 12.425 13.4 12.35 13.6917 12.45C14.625 12.7583 15.6333 12.925 16.6667 12.925C17.125 12.925 17.5 13.3 17.5 13.7583V16.6667C17.5 17.125 17.125 17.5 16.6667 17.5C8.84167 17.5 2.5 11.1583 2.5 3.33333C2.5 2.875 2.875 2.5 3.33333 2.5H6.25C6.70833 2.5 7.08333 2.875 7.08333 3.33333C7.08333 4.375 7.25 5.375 7.55833 6.30833C7.65 6.6 7.58333 6.925 7.35 7.15833L5.51667 8.99167Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_173">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const Mail = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_69_178)">
        <path
          d="M16.6667 3.33333H3.33335C2.41669 3.33333 1.67502 4.08333 1.67502 4.99999L1.66669 15C1.66669 15.9167 2.41669 16.6667 3.33335 16.6667H16.6667C17.5834 16.6667 18.3334 15.9167 18.3334 15V4.99999C18.3334 4.08333 17.5834 3.33333 16.6667 3.33333ZM16.6667 6.66666L10 10.8333L3.33335 6.66666V4.99999L10 9.16666L16.6667 4.99999V6.66666Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_178">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
