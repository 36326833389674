import logo from "../../assets/img/logo.png";
import logoMobile from "../../assets/img/logoMobile.png";
import { Mail, Phone, Place, Time } from "../../assets/svg/iconsInfo";
import { Inst, InstHover, Telegram, Viber } from "../../assets/svg/iconsMessengers";
import { inst, telegram, viberMobile, viberWeb } from "./constants/links";

const Header = () => {
  const handlePhoneClick = (phoneNumber) => {
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  const handleEmailClick = () => {
    const email = "info@seatravel.by";
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink, "_blank");
  };
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <header className="header">
      <div className="header__wrapperLogo">
        <div className="logo">
          <img src={logo} alt="logo"></img>
        </div>
        <h3 className="title">Окунись в море путешествий</h3>
      </div>
      <div className="header__wrapperMenu">
        <div className="logoMobile">
          <img src={logoMobile} alt="logo"></img>
        </div>
        <div className="wrapperInfo">
          <div className="wrapperInfo__info">
            <div className="icon">
              <Place />
            </div>
            <div className="wrapperText">
              <p className="text">ул. Гикало, 3</p>
              <p className="text">ул. Лобанка, 81, оф.4</p>
            </div>
          </div>
          <div className="wrapperInfo__info">
            <div className="icon">
              <Time />
            </div>
            <div className="wrapperText">
              <p className="text">Пн-Пт, 10:00-19:00</p>
            </div>
          </div>
          <div className="wrapperInfo__info">
            <div className="icon">
              <Phone />
            </div>
            <div className="wrapperText">
              <p
                className="text cursor"
                onClick={() => handlePhoneClick("+375173882200")}
              >
                +375 17 388 22 00
              </p>
              <p
                className="text cursor"
                onClick={() => handlePhoneClick("+375293102200")}
              >
                +375 29 310 22 00
              </p>
              <p
                className="text cursor"
                onClick={() => handlePhoneClick("+375333102202")}
              >
                +375 33 310 22 02
              </p>
            </div>
          </div>
          <div className="wrapperInfo__info">
            <div className="icon">
              <Mail />
            </div>
            <div className="wrapperText">
              <p className="text cursor" onClick={handleEmailClick}>
                info@seatravel.by
              </p>
            </div>
          </div>
        </div>
        <div className="messengersIcons">
          <a className="messengersIcons__icon" href={inst} target="blank">
            <div className="iconInst"><Inst /></div>
            <div className="iconInstHover"><InstHover /></div>
          </a>
          <a className="messengersIcons__icon" href={isMobile ? viberMobile : viberWeb} target="blank">
            <div className="iconViber"><Viber /></div>
          </a>
          <a className="messengersIcons__icon" href={telegram} target="blank">
            <div className="iconTelegram"><Telegram /></div>
          </a>
        </div>

      </div>

    </header>
  );
};

export default Header;
