import Footer from "./components/Footer";
import Header from "./components/Header";
import PayIcons from "./components/PayIcons";
import SearchForm from "./components/SearchForm";
import YandexMap from "./components/YandexMap";

function App() {
  return (
    <div className="App">
      <Header />
      <SearchForm />
      <YandexMap />
      <PayIcons />
      <Footer />
    </div>
  );
}

export default App;
