import { Placemark, Map, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import { API_KEY_MAP } from "./constants/api";
import {
  coordinates1,
  coordinates2,
  coordinatesCenter,
} from "./constants/coordinates";

const YandexMap = () => {
  return (
    <section className="yandexMap">
      <h2 className="yandexMap__title">Наши офисы на карте Минска</h2>
      <div className="yandexMap__map">
        <YMaps
          query={{
            apikey: API_KEY_MAP,
            lang: "ru_RU",
          }}
        >
          <Map
            defaultState={{ center: coordinatesCenter, zoom: 12 }}
            width="100%"
            height="100%"
          >
            <Placemark geometry={coordinates1} />
            <Placemark geometry={coordinates2} />
            <ZoomControl options={{ float: "right" }} />
          </Map>
        </YMaps>
      </div>
    </section>
  );
};

export default YandexMap;
